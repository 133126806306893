.content.is-small {
  letter-spacing: 0.4px;
}

th {
  font-weight: $weight-bold;
}
table {
  font-size: $size-7;
  @include fullhd {
    font-size: $size-6;
  }
}

//Title

.title {
  color: $dark;
}

h1.title.is-2 {
  > .icon {
    position: relative;
    top: 1.5px;
  }
}

h1,
.title.is-1,
.h1 {
  font-weight: $weight-light;

  @include touch {
    font-size: 3.2rem;
  }
}

.title--small {
  font-weight: $weight-bold;
  text-transform: uppercase;
  color: #303032;
  padding-bottom: 5px;
  display: inline-block;
  font-size: $size-8;
  margin-bottom: 0;
}
// Badges

.badge {
  height: 25px;
  margin: 2.5px 5px 2.5px 0;
  padding: 2px 5px 0 5px !important;
  font-weight: $weight-bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @include touch {
    font-size: 0.825em;
  }
  &.is-info {
    background-color: $info-light;
    color: $link;
  }
  &.is-success {
    background-color: $success-light;
    color: $success;
  }
  &.is-link {
    background-color: $link-light;
    color: $link;
  }
}

.total {
  position: absolute;
  top: 0;
  right: 30px;

  &.aligned-to-title {
    top: -30px;
  }

  &--alt {
    right: 0;
    top: unset;
    bottom: 0;
  }
}

//_ Global rules
hr {
  height: 1px;
  background-color: $border-color;
}

a {
  cursor: pointer;
  &.no-deco {
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  &.become-link {
    color: $grey;
    &:hover,
    &:focus,
    &:active {
      color: $link !important;
    }
  }
}

*[routerLink],
*[href],
*[ng-reflect-router-link] {
  cursor: pointer;
}

.is-italic {
  font-style: italic;
}
.no-transfo {
  text-transform: none;
}
.is-regular {
  font-weight: $weight-normal;
}

a {
  &[disabled] {
    cursor: no-drop;
    pointer-events: none;
    color: $grey-light;
  }
}
//_ Specific rules
.status-text {
  font-size: 1.1rem;
  font-weight: $weight-bold;
  text-transform: uppercase;
}

.coma-list {
  display: inline-block;
  + .coma-list {
    &:before {
      content: ',';
      padding-right: 2px;
    }
  }
}

//_ Tooltip
.tooltip {
  &:before {
    background-color: $dark !important;
    border: 1px solid $dark;
    color: $light !important;
    font-size: 1.3rem !important;
    z-index: 99;
  }
}

.is-default,
.is-default a {
  cursor: default;
}

.has-custom-link {
  text-decoration: underline;

  &:hover {
    color: $link;
  }
}
