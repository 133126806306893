.is-white-space-nowrap {
  white-space: nowrap;
}

.is-fullheight {
  height: 100%;
}
.is-fullwidth {
  width: 100%;
}

.is-absolute {
  position: absolute;
}
