.avatar {
  display: block;
  border-radius: 50%;
  border: 1px solid $light;
  position: relative;
  overflow: hidden;

  &.is-number {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $link;
    color: $light;
  }

  &:not(.is-large):not(.is-small) {
    width: $avatar-diameter;
    min-width: $avatar-diameter;
    max-width: $avatar-diameter;
    height: $avatar-diameter;
    min-height: $avatar-diameter;
    max-height: $avatar-diameter;
  }
  &.is-small {
    width: $avatar-diameter-small;
    min-width: $avatar-diameter-small;
    max-width: $avatar-diameter-small;
    height: $avatar-diameter-small;
    min-height: $avatar-diameter-small;
    max-height: $avatar-diameter-small;
  }
  &.is-large {
    width: $avatar-diameter-large;
    min-width: $avatar-diameter-large;
    max-width: $avatar-diameter-large;
    height: $avatar-diameter-large;
    min-height: $avatar-diameter-large;
    max-height: $avatar-diameter-large;
  }
}

.has-avatar {
  position: relative;
  z-index: 6;
  height: 100%;
  display: inline-flex;
  align-items: center;

  @for $i from 1 through 9 {
    &:nth-child(#{$i}) {
      left: calc(#{$i} * 8px * -1);
      z-index: #{6 - $i};
    }
  }
}
