// Navbar
$navbar-background-color: $white !default;
$navbar-height: 8rem !default;
$navbar-item-color: $dark !default;
$navbar-item-hover-color: $link !default;
$navbar-item-active-color: $link !default;
$navbar-item-img-max-height: unset !default;

// Custom variables
$aside-width-tablet: 25% !default;
$aside-width-desktop: 20% !default;
$aside-width-widescreen: 20% !default;
$aside-width-fullhd: 12% !default;
$aside-width-collapsed: 77px !default;

$aside-border-width: 1px !default;

$sidenav-item-hover-color: $link-light !default;
$sidenav-item-color: $black !default;
$sidenav-background-color: $navbar-background-color !default;
