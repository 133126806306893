@use 'sass:list';
@use 'sass:math';
/* You can add global styles to this file, and also import other style files */
/* insert custom variables here to override existing ones or create new ones */

@import 'styles/variables/all';
@import './app/abacus/styles/main.scss';

.logo {
  width: 196px;
  max-width: 196px;
}
