.aside {
  background: $sidenav-background-color;
  flex: none;
  @include tablet {
    width: $aside-width-tablet;
  }
  @include desktop {
    width: $aside-width-desktop;
  }
  @include widescreen {
    width: $aside-width-widescreen;
  }
  @include fullhd {
    width: $aside-width-fullhd;
  }
  .is-collapsed & {
    width: 78px !important;
  }
}

.dropdown-trigger:after {
  @extend %icon;
  @extend .icon-chevron-down;
}

.navbar {
  position: relative;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    height: 100%;
    border-bottom: 1px solid $border-color;
    @include mobile {
      width: 100%;
    }

    @include tablet {
      left: calc(#{$aside-width-tablet} + #{$aside-border-width});
      width: calc(100% - #{$aside-width-tablet} - #{$aside-border-width});
    }
    @include desktop {
      left: calc(#{$aside-width-desktop} + #{$aside-border-width});
      width: calc(100% - #{$aside-width-desktop} - #{$aside-border-width});
    }
    @include widescreen {
      left: calc(#{$aside-width-widescreen} + #{$aside-border-width});
      width: calc(100% - #{$aside-width-widescreen} - #{$aside-border-width});
    }
    @include fullhd {
      left: calc(#{$aside-width-fullhd} + #{$aside-border-width});
      width: calc(100% - #{$aside-width-fullhd} - #{$aside-border-width});
    }

    .is-collapsed & {
      left: $aside-width-collapsed;
      width: calc(100% - #{$aside-width-collapsed});
    }
  }
  > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sidebar {
  float: left;
}

.collapse-button {
  position: absolute;
  height: $navbar-height;
  width: $navbar-height;
  border: 1px solid $border-color;
  top: 0;
  left: 306px;
  z-index: 33;
  display: flex;
  align-items: center;
  justify-content: center;

  @include desktop {
    left: calc(#{$aside-width-desktop} + #{$aside-border-width});
  }
  @include widescreen {
    left: calc(#{$aside-width-widescreen} + #{$aside-border-width});
  }
  @include fullhd {
    left: calc(#{$aside-width-fullhd} + #{$aside-border-width});
  }

  a {
    height: 100%;
    width: 100%;
  }
}

// TODO : Put all this css in the appropriate component when JS is developed
.is-collapsed {
  li a {
    i + span,
    span + i {
      display: none;
    }
  }
  aside .has-submenu + ul {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 9;
    background: $sidenav-background-color;
    box-shadow: 10px 10px 20px rgba(48, 48, 50, 0.07);

    li a {
      padding-left: $gap !important;
    }
  }
}
.navbar-burger {
  @include desktop {
    display: block;
    margin: unset;
  }
}
