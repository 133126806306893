$icomoon-font-family: 'case-icon' !default;
$icomoon-font-path: '/assets/fonts' !default;

$icon-dimensions: 1.5rem !default;
$icon-dimensions-small: 1rem !default;
$icon-dimensions-medium: 2.4rem !default;
$icon-dimensions-large: 2.8rem !default;

$icon-award1: '\e918';
$icon-bar-chart-21: '\e919';
$icon-bar-chart1: '\e91a';
$icon-log-in1: '\e91b';
$icon-activity: '\e91c';
$icon-airplay: '\e91d';
$icon-alert-circle: '\e91e';
$icon-anchor: '\e91f';
$icon-aperture: '\e920';
$icon-at-sign: '\e921';
$icon-award: '\e922';
$icon-bar-chart-2: '\e923';
$icon-bar-chart: '\e924';
$icon-battery-charging: '\e925';
$icon-bell: '\e926';
$icon-book-open: '\e927';
$icon-book: '\e928';
$icon-bookmark: '\e929';
$icon-box: '\e92a';
$icon-briefcase: '\e92b';
$icon-calendar: '\e92c';
$icon-camera-off: '\e92d';
$icon-camera: '\e92e';
$icon-check-circle: '\e92f';
$icon-check3: '\e930';
$icon-chevrons-down: '\e931';
$icon-chevrons-left: '\e932';
$icon-chevrons-right: '\e933';
$icon-chevrons-up: '\e934';
$icon-chrome: '\e935';
$icon-circle: '\e936';
$icon-clock: '\e937';
$icon-cloud-snow: '\e938';
$icon-cloud: '\e939';
$icon-code: '\e93a';
$icon-codepen: '\e93b';
$icon-coffee: '\e93c';
$icon-compass: '\e93d';
$icon-copy: '\e93e';
$icon-corner-up-right: '\e93f';
$icon-cpu: '\e940';
$icon-credit-card: '\e941';
$icon-crop: '\e942';
$icon-crosshair: '\e943';
$icon-database: '\e944';
$icon-download-cloud: '\e945';
$icon-download: '\e946';
$icon-droplet: '\e948';
$icon-external-link: '\e949';
$icon-eye: '\e94a';
$icon-facebook: '\e94b';
$icon-file-plus: '\e94c';
$icon-file-text: '\e94d';
$icon-file: '\e94e';
$icon-film: '\e94f';
$icon-filter: '\e950';
$icon-flag: '\e951';
$icon-folder: '\e952';
$icon-git-branch: '\e953';
$icon-git-commit: '\e954';
$icon-git-merge: '\e955';
$icon-git-pull-request: '\e956';
$icon-github: '\e957';
$icon-gitlab: '\e958';
$icon-globe: '\e959';
$icon-grid: '\e95a';
$icon-hard-drive: '\e95b';
$icon-hash: '\e95c';
$icon-headphones: '\e95d';
$icon-heart: '\e95e';
$icon-help-circle: '\e95f';
$icon-home: '\e960';
$icon-image: '\e961';
$icon-inbox: '\e962';
$icon-info: '\e963';
$icon-instagram: '\e964';
$icon-layers: '\e965';
$icon-layout: '\e966';
$icon-life-buoy: '\e967';
$icon-link-2: '\e968';
$icon-link: '\e969';
$icon-linkedin: '\e96a';
$icon-list: '\e96b';
$icon-lock: '\e96c';
$icon-log-in: '\e96d';
$icon-log-out: '\e96e';
$icon-mail: '\e96f';
$icon-map-pin: '\e970';
$icon-map: '\e971';
$icon-maximize-2: '\e972';
$icon-maximize: '\e973';
$icon-menu: '\e974';
$icon-message-circle: '\e975';
$icon-message-square: '\e976';
$icon-mic: '\e977';
$icon-minimize-2: '\e978';
$icon-minimize: '\e979';
$icon-monitor: '\e97a';
$icon-more-horizontal: '\e97b';
$icon-more-vertical: '\e97c';
$icon-move: '\e97d';
$icon-navigation: '\e97e';
$icon-package: '\e97f';
$icon-paperclip: '\e980';
$icon-pause-circle: '\e981';
$icon-percent: '\e982';
$icon-phone-call: '\e983';
$icon-phone: '\e984';
$icon-pie-chart: '\e985';
$icon-play-circle: '\e986';
$icon-play: '\e987';
$icon-plus-circle: '\e988';
$icon-plus-square: '\e989';
$icon-plus: '\e98a';
$icon-power: '\e98b';
$icon-printer: '\e98c';
$icon-save: '\e98d';
$icon-search: '\e98e';
$icon-server: '\e990';
$icon-settings: '\e991';
$icon-share-2: '\e992';
$icon-share: '\e993';
$icon-shield: '\e994';
$icon-shopping-bag: '\e995';
$icon-shopping-cart: '\e996';
$icon-shuffle: '\e997';
$icon-slack: '\e998';
$icon-sliders: '\e999';
$icon-smartphone: '\e99a';
$icon-smile: '\e99b';
$icon-square: '\e99c';
$icon-star: '\e99d';
$icon-tablet: '\e99e';
$icon-tag: '\e99f';
$icon-target: '\e9a0';
$icon-thumbs-up: '\e9a1';
$icon-toggle-right: '\e9a2';
$icon-trash-2: '\e9a3';
$icon-trending-up: '\e9a4';
$icon-twitter: '\e9a5';
$icon-upload-cloud: '\e9a6';
$icon-upload: '\e9a7';
$icon-x-circle: '\e9a8';
$icon-x-square: '\e9a9';
$icon-x: '\e9aa';
$icon-youtube: '\e9ab';
$icon-zap: '\e9ac';
$icon-alert-octagon: '\e900';
$icon-alert-triangle: '\e901';
$icon-archive: '\e902';
$icon-arrow-bottom-circle: '\e903';
$icon-arrow-bottom: '\e904';
$icon-arrow-left-circle: '\e905';
$icon-arrow-left: '\e906';
$icon-arrow-right-circle: '\e907';
$icon-arrow-right: '\e908';
$icon-arrow-up-circle: '\e909';
$icon-arrow-up: '\e90a';
$icon-check1: '\e90b';
$icon-chevron-down: '\e90c';
$icon-chevron-left: '\e90d';
$icon-chevron-right: '\e90e';
$icon-chevron-up: '\e90f';
$icon-clipboard: '\e910';
$icon-delete: '\e911';
$icon-edit-2: '\e912';
$icon-edit-3: '\e913';
$icon-edit: '\e914';
$icon-user-plus: '\e915';
$icon-user: '\e916';
$icon-users: '\e917';
$icon-check: '\2611';
$icon-check2: '\e947';
$icon-Author: '\1f4dd';
$icon-tick1: '\1f5f8';
