.dropdown-item {
  font-size: $size-6;
  padding-right: 3rem;
}

.dropdown-menu {
  &.is-large {
    min-width: $dropdown-menu-large-min-width;
  }
}
.dropdown.is-west {
  .dropdown-menu {
    right: 200%;
    left: unset;
  }

  &:not(.is-up) {
    .dropdown-menu {
      top: -1.5rem;
      bottom: unset;
    }
  }
  .rev-pos-0 &,
  &.is-up {
    .dropdown-menu {
      bottom: -1.5rem;
      top: unset;
    }
  }
}
