.tabs-content > div {
  display: none;

  &.is-active {
    display: block;
  }
}
tr {

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    &.is-#{$name} td {
      background-color: rgba($color, 0.04);
    }
  }
}
