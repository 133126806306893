$info: #06838f !default;
$info-light: #ddf1f3 !default;

$primary: #142f4e !default;
$primary-light: #e0eef8 !default;

$success: #0bb89c !default;
$success-light: #d4f4ef !default;

$link: #258cbc !default;
$link-light: #e1f2fa !default;

$warning: #f8a94a !default;
$warning-light: #fef6ee !default;

$danger: #f46470 !default;
$danger-light: #fef0f1 !default;

// Additional values

$link-alpha10: rgba($link, 0.1) !default;
$link-alpha07: rgba($link, 0.07) !default;
$link-alpha03: rgba($link, 0.03) !default;
