$modal-z: 999 !default;
$modal-background-background-color: rgba($black, 0.86) !default;

$modal-content-width: 430px !default;
$modal-content-margin-mobile: 0 !default;
$modal-content-spacing-mobile: 0 !default;
$modal-content-spacing-tablet: 0 !default;

$modal-card-head-background-color: $white !default;
$modal-card-head-border-bottom: 0 !default;
$modal-card-head-padding: $gap !default;
$modal-card-head-radius: 4px !default;

$modal-card-title-color: $black !default;
$modal-card-title-line-height: 1.125 !default;
$modal-card-title-size: $size-2 !default;

$modal-card-foot-radius: 4px !default;
$modal-card-foot-border-top: 0 !default;

$modal-card-body-padding: $gap !default;
