$spacing-values: (
  '0': 0,
  '1': 0.5rem,
  '2': 0.75rem,
  '3': 1rem,
  '4': 1.5rem,
  '5': 2rem,
  '6': 3rem,
  '7': 4rem,
  '8': 5rem,
  '9': 6rem,
  '10': 8rem
) !default;

$block-spacing: 2rem !default;
