// Grid
$gap: 30px !default;
$column-gap: 15px !default;

// Breakpoints
$small-mobile: 375px !default;
$tablet: 768px !default;
$desktop: 1000px !default;
$widescreen: 1188px !default;
$fullhd: 1458px !default;
$retina: 1458px !default;
