$col-left-width-mobile: 100% !default;
$col-left-width-tablet: 66.66667% !default;
$col-left-width-desktop: 40% !default;
$col-left-width-widescreen: 33.33333% !default;

$col-left-max-width-mobile: unset !default;
$col-left-max-width-tablet: 328px !default;
$col-left-max-width-desktop: 328px !default;
$col-left-max-width-widescreen: 328px !default;

$col-left-min-width-mobile: 86% !default;
$col-left-min-width-tablet: 43% !default;
$col-left-min-width-desktop: 324px !default;
$col-left-min-width-widescreen: 324px !default;
