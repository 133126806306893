// Buttons
$control-border-width: 1px !default;
$button-hover-color: $danger !default;
$button-padding-horizontal: 1.38em !default;
$radius: 2px !default;
$control-height: 3em !default;
$control-shadow-secondary: 0 6px 16px 0 rgba($primary, 0.2) !default;
$size-small: 1.2rem !default;

// Custom variables
$perimeter: 4.5rem !default;
$perimeter-small: 3.6rem !default;
$perimeter-medium: 5.4rem !default;
$perimeter-large: 6.3rem !default;
