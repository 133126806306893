.modal-card {
  @include mobile {
    min-height: 100vh;
    border-radius: 0;
  }
}

.modal-card-head {
  @include mobile {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.modal-card-footer {
  @include mobile {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.modal.is-large .modal-content,
.modal.is-large .modal-card {
  @include tablet {
    min-width: 768px;
    max-width: 100vw;
  }
}

.modal {
  .modal-card-title {
    flex: 1;
  }
}

.modal.is-right {
  display: block;
  visibility: hidden;
  transition: all .02s linear .18s;

  .modal-background {
    opacity: 0;
    visibility: hidden;
    transition: opacity .18s ease-out, visibility .02s ease-out .18s;
  }

  .modal-content,
  .modal-card {
    position: fixed;
    right: #{-1 * $modal-content-width};
    transition: all .18s ease-in-out;
    height: 100vh;
    min-height: 100vh;
    top: 0;
  }


  &.is-active {
    transition: all .02s linear;
    visibility: visible;

    .modal-content,
    .modal-card {
      right: 0;
      transition: all .18s ease-in-out;
    }

    .modal-background {
      opacity: 1;
      visibility: visible;
      transition: opacity .18s ease-out .02s, visibility .02s ease-out;

    }
  }
}
