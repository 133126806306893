%is-hovered {
  background-color: $sidenav-item-hover-color;
  color: $link;
}
.menu-list {
  li {
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: $size-5;

    ul {
      padding-left: 0;
      margin: 0;
      border-left: 0;
    }

    a {
      position: relative;
      color: $sidenav-item-color;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      min-height: 57px;
      padding: 19px $gap;
      transition: color 0.35s ease, background-color 0.05s ease-out;
      -webkit-transition: color 0.35s ease, background-color 0.05s ease-out;
      -moz-transition: color 0.35s ease, background-color 0.05s ease-out;
      -ms-transition: color 0.35s ease, background-color 0.05s ease-out;
      -o-transition: color 0.35s ease, background-color 0.05s ease-out;

      i {
        height: 18px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 5px;
        background-color: transparent;
      }

      &.has-submenu {
        justify-content: space-between;
        display: inline-flex;

        > span {
          display: inline-flex;
          align-items: center;
        }

        + ul {
          $border-color: rgba($grey-lighter, 0.1);
          padding-top: 1px;
          border-bottom: 1px solid $border-color;
          border-left: 0;
          li a {
            padding-left: 62px;
          }
        }
      }
    }
  }

  a {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;

    &:hover {
      @extend %is-hovered;
      transition: color 0.35s ease, background-color 0.05s ease-in;
      -webkit-transition: color 0.35s ease, background-color 0.05s ease-in;
      -moz-transition: color 0.35s ease, background-color 0.05s ease-in;
      -ms-transition: color 0.35s ease, background-color 0.05s ease-in;
      -o-transition: color 0.35s ease, background-color 0.05s ease-in;
    }

    &.is-active {
      @extend %is-hovered;
      &:before {
        background-color: $link;
      }
    }
  }
}

.flex {
  display: flex;
}
