// Breadcrumbs

case-breadcrumbs {
  @include tablet {
    position: absolute;
    left: calc(#{$aside-width-tablet} + #{$main-padding-horizontal});
    z-index: 30;
    top: 50%;
    transform: translateY(-50%);
  }
  @include desktop {
    left: calc(#{$aside-width-desktop} + #{$gap} + #{$navbar-height});
  }

  @include fullhd {
    left: calc(#{$aside-width-fullhd} + #{$main-padding-horizontal});
  }
}
