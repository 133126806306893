$table-cell-padding: 1.112em 0.75em !default;
$table-cell-padding-narrow: 0.5775em 0.5em !default;

$table-head-cell-border-width: 0 0 1px !default;
$table-head-cell-color: $grey !default;
$table-foot-cell-border-width: 1px 0 0 !default;
$table-foot-cell-color: $grey !default;

$table-row-hover-background-color: rgba($link, 0.01) !default;

$table-striped-row-even-background-color: rgba($white-bis, 0.5) !default;
$table-striped-row-even-hover-background-color: rgba($white-ter, 0.5) !default;

// Custom variables
$border-width-table-row-highlighted: 7px !default;
