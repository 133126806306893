.has-notification {
  position: absolute;
  right: -3px;
  top: -3px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $has-notification-width;
  width: $has-notification-width;
  border-radius: $has-notification-width;

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: $color;
      color: $color-invert;
    }
  }

  > span {
    position: relative;
    top: 1px;
    left: 0;
    display: flex;
    font-family: $family-sans-serif;
    font-size: $size-8;
    font-weight: $weight-bold;
    line-height: $size-8;
    color: $white;
  }
}

.notification {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 9999;
  @include tablet {
    width: 320px;
  }

  @include mobile {
    width: calc(100% - 4rem);
  }
}

.notification-item {
  border-bottom: 1px solid $border-color;
}

.notification-dot {
  width: $notification-dot-width;
  height: $notification-dot-width;
  background-color: $link;
  border-radius: $notification-dot-width;
  display: inline-block;
  margin-left: $gap;
}
.notification-excerpt {
  width: calc(100% - #{$gap} - #{$notification-dot-width});
}
