// generic
$shadow: 0 10px 20px 0 rgba($dark, 0.07) !default;
$border-color: $white-ter !default;
$radius-large: 3px !default;

// Body
$body-size: 10px !default;
$body-size-touch: 10.22px !default;

// Custom variables
$main-padding-horizontal: 110px !default;
