$card-color: $black !default;
$card-radius: 5px !default;
$border-color: $white-ter !default;
$card-header-color: inherit !default;
$card-header-padding: 2rem !default;
$card-content-padding: 2rem !default;
$card-header-shadow: 0 1px 0 $grey-lighter !default;
$card-shadow: 0 10px 20px rgba($dark, 0.07) !default;

$card-footer-padding: 2rem !default;
$card-footer-border-top: 1px solid $grey-lighter !default;
