$pagination-border-color: transparent !default;

$pagination-disabled-color: rgba($grey, 0.75) !default;

$pagination-ellipsis-color: rgba($grey, 0.75) !default;
$pagination-shadow-inset: none;

$pagination-hover-color: $link !default;

$pagination-focus-color: $link !default;

$pagination-active-color: $link !default;

$pagination-disabled-color: $grey-dark !default;
$pagination-disabled-background-color: $grey-light !default;
$pagination-disabled-border-color: $grey-light !default;
