$black: #303032 !default; // Overload
$white: #fff !default; // Overload

$dark: $black !default; // Overload
$light: $white !default; // Overload

$black-bis: $dark !default;
$black-ter: $dark !default;
$grey-darker: $dark !default;
$grey-dark: #7e6d72;
$grey: #b9b5b8 !default;
$grey-light: #ebeef1 !default;
$grey-lighter: #e9eeff !default;
$white-ter: #f7f7f7 !default;
$white-bis: #f5f7f9 !default;

$link: #5c1ee8 !default;
$link-light: #eee8fd !default;

$primary: #466b84 !default;
$primary-light: #f1f9ff !default;

$success: #57b3a0 !default;
$success-light: #eff7f5 !default;

$info: #1eb1e8 !default;
$info-light: #ecf7fd !default;

$warning: #f8a94a !default;
$warning-light: #fef6ee !default;

$danger: #f46470 !default;
$danger-light: #fef0f1 !default;

$border-color: $grey-lighter !default;

// Additional values

$link-alpha10: rgba($link, 0.1) !default;
$link-alpha07: rgba($link, 0.07) !default;
$link-alpha03: rgba($link, 0.03) !default;
$main-background: $white-bis !default;
$border-color-input: $link-alpha10 !default;

$icon-color-1: #ad8875 !default;
$icon-color-2: #6f627a !default;
$icon-color-3: #6f627a !default;
$icon-color-4: #ff8080 !default;
$icon-color-5: #774e94 !default;
$icon-color-6: #87655c !default;
$icon-color-7: #ffc155 !default;
$icon-color-8: #fa8c43 !default;
$icon-color-9: #53788d !default;
$icon-color-10: $danger !default;
$icon-color-11: $success !default;
$icon-color-12: $warning !default;
$icon-color-13: $link !default;
$icon-color-13: $info !default;
