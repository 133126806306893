$dropdown-menu-min-width: 15rem !default;

$dropdown-item-hover-color: $link !default;
$dropdown-item-hover-background-color: $link-light !default;
$dropdown-item-active-color: $link !default;
$dropdown-item-active-background-color: $link-light !default;

$dropdown-divider-background-color: $border-color !default;

// Custom style
$dropdown-menu-large-min-width: 25rem !default;
