.is-12percent-fullhd {
  @include fullhd {
    flex: none;
    width: 12% !important;
  }
}
.is-1-5 {
  @include desktop {
    flex: none;
    width: 12.5% !important;
  }
  @include touch {
    width: 100%;
  }
}
.is-20-percent {
  @include desktop {
    flex: none;
    width: 20% !important;
  }
  @include touch {
    width: 100%;
  }
}

.main.column {
  @include touch {
    width: 100%;
    padding: $column-gap $gap;
  }
  @include desktop {
    padding: $column-gap $gap;
    width: calc(100% - #{$aside-width-desktop});
  }
  @include widescreen {
    width: calc(100vw - #{$aside-width-widescreen} - #{$gap});
  }
  @include fullhd {
    padding: $column-gap 110px;
    width: calc(
      100vw - #{$aside-width-widescreen} - #{$main-padding-horizontal * 2}
    );
  }

  .is-collapsed & {
    @include desktop {
      width: calc(
        100vw - #{$aside-width-collapsed} - #{$aside-border-width} - #{$main-padding-horizontal}
      );
    }
  }
}
