$bullet-colors: (
  progress-1: #240986,
  progress-2: #5c1ee8,
  progress-3: #a676f8,
  progress-4: #00c3f9,
  progress-5: #65f2fd,
  progress-6: #73e6d0
);

$progress-0: #e8e8e8 !default;

$bullet-width: 8px !default;
$bullet-height: $bullet-width !default;
$margin-top: 2px !default;
$margin-right: 2px !default;
$margin-bottom: 2px !default;
